<template>
  <div class="prve">
    <a-carousel
      :autoplay="false"
      arrows
      dotsClass="dots-click"
      class="banner-swiper"
    >
      <!-- 箭头 -->
      <div
        slot="prevArrow"
        class="custom-slick-arrow arrow-prev"
        style="left: 10px; zindex: 1"
      />
      <div
        slot="nextArrow"
        class="custom-slick-arrow arrow-next"
        style="right: 10px"
      />
      <!-- 箭头end -->
      <div class="swiper-slide" v-if="banner[0]">
        <div class="swiper-slide-item">
          <!--<img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/banner1.jpg"
          />-->
          <img :src="banner[0].imageUrl" />
          <!-- <div class="bannerTxt txt1">
            <p class="f1">宏远控股</p>
            <p class="f2">跨境贸易综合服务商</p>
            <p class="f3">
              CROSS BORDER TRADE INTEGRATED<br />
              SERVICE PROVIDER
            </p>
            <a class="bnnr-btn" href="" @click="jump($event, '/about/1')"
              >了解更多</a
            >
          </div> -->
        </div>
      </div>
      <div class="swiper-slide" v-if="banner[1]">
        <div class="swiper-slide-item">
          <!--  <img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/banner2.jpg"
          />--->
          <img :src="banner[1].imageUrl" />
          <!-- <div class="bannerTxtBg nonde">
            <div class="bt-bg">
              <dl class="clearfix">
                <dt>
                  <img
                    src="http://www.pekhongyuan.com/pic/hongyuan/images/banner21.png"
                  />
                </dt>
                <dd>
                  <p>
                    <strong>北京2022冬奥运主物流中心(MDC)项目</strong
                    >&nbsp;&nbsp;&nbsp;&nbsp;是空港宏远作为“北京2022年冬奥会和冬残奥会官方仓储设施独家供应商”，专项打造的赛会重要的非竞赛场馆之一，是家具白电、技术设备、体育器材等赛物资集中储存场所，同时担任着物资分拨、配送通关协调及通关协调总体配送计划编制等服务保障工作。
                  </p>
                  <a class="bnnr-btn" href="" @click="jump($event, '/about/5')"
                    >了解更多</a
                  >
                </dd>
              </dl>
            </div>
          </div> -->
        </div>
      </div>
      <div class="swiper-slide" v-if="banner[2]">
        <div class="swiper-slide-item">
          <!--<img
            src="http://www.pekhongyuan.com/pic/hongyuan/images/banner3.jpg"
          />-->
          <img :src="banner[2].imageUrl" />
          <!-- <div class="bannerTxt txt3">
            <p class="f1">愿景与使命</p>
            <p class="f2">Vision and mission</p>
            <p class="f3">
              成为一家值得信赖的、受人尊敬的 跨境贸易综合服务商。<br />让世界更近，生活更美好。
            </p>
            <a class="bnnr-btn" href="" @click="jump($event, '/about/3')"
              >了解更多</a
            >
          </div> -->
        </div>
      </div>
    </a-carousel>
  </div>
</template>
<script>
export default {
  props: {
    banner: {
      type: Array,
      default: () => [
        {
          imageUrl: ''
        }
      ]
    }
  },
  methods: {
    jump ($event, path) {
      $event.preventDefault()
      this.$router.push({ path: path })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-carousel .custom-slick-arrow {
  width: 0.36rem;
  height: 0.62rem;
  color: #fff;
  margin-top: -0.3rem;
  &::before {
    display: none;
  }
  &.arrow-prev {
    background-image: url("http://www.pekhongyuan.com/pic/hongyuan/images/ic-arrow-l.png");
    left: 0.2rem;
    right: auto;
  }
  &.arrow-next {
    background-image: url("http://www.pekhongyuan.com/pic/hongyuan/images/ic-arrow-r.png");
    right: 0.2rem;
    left: auto;
  }
}
.swiper-slide {
  height: auto;
  &-item {
    position: relative;
  }
  img {
    width: 100%;
    height: auto;
  }
  .bannerTxt {
    max-width: 90%;
    margin: 0 auto;
    color: #fff;
    position: absolute;
    left: 11%;
    bottom: 12%;
    text-align: left;
    line-height: 1.5;
    .f1 {
      font-size: 0.72rem;
      padding-bottom: 0.16rem;
      letter-spacing: 0.05rem;
    }
    .f2 {
      font-size: 0.36rem;
      padding-bottom: 0.2rem;
      letter-spacing: 0.05rem;
    }
    .f3 {
      font-size: 0.2rem;
      padding-bottom: 0.14rem;
      letter-spacing: 0.03rem;
    }
    .bnnr-btn {
      padding: 0 0.2rem;
      border: 2px solid #fff;
      color: #fff;
      letter-spacing: 0.05px;
      font-size: 0.21rem;
      line-height: 2;
      border-radius: 0.06rem;
      display: inline-block;
    }
  }
  .bannerTxtBg {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 12%;
    .bannerTxt {
      position: static;
    }
  }
  .bt-bg {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.3rem 0;
    dl {
      width: 12rem;
      margin: auto;
      font-size: 0.22rem;
      color: #fff;
      position: relative;
      zoom: 1;
      dt {
        width: 3rem;
        float: left;
        img {
          width: 2.9rem;
        }
      }
      dd {
        line-height: 2;
      }
    }
    .bnnr-btn {
      position: absolute;
      right: 0.1rem;
      bottom: 0.1rem;
      padding: 0 0.2rem;
      border: 2px solid #fff;
      color: #fff;
      letter-spacing: 5px;
      font-size: 0.21rem;
      line-height: 2;
      border-radius: 6px;
      display: inline-block;
    }
  }
}
@media screen and(max-width:900px) {
  .swiper-slide .bannerTxt {
    bottom: 2%;
  }
  .swiper-slide img {
    // height: 250px;
  }
  .nonde {
    display: none;
  }
  .arrow-next,
  .arrow-prev {
    display: none !important;
  }
}
</style>
<style lang="less">
.banner-swiper.ant-carousel .dots-click li {
  button {
    width: 0.14rem;
    height: 0.14rem;
    border-radius: 50%;
  }
}
.banner-swiper.ant-carousel .dots-click li.slick-active {
  button {
    width: 0.4rem;
    height: 0.14rem;
    background-color: #6cbbe9;
    border: 1px solid #fff;
    border-radius: 7px;
  }
}
.banner-swiper.ant-carousel .slick-dots-bottom {
  bottom: 0.4rem;
}
@media screen and(max-width:900px) {
  .banner-swiper.ant-carousel .dots-click li {
    button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
  .banner-swiper.ant-carousel .dots-click li.slick-active {
    button {
      width: 10px;
      height: 10px;
      background-color: #6cbbe9;
      border: 1px solid #fff;
      border-radius: 7px;
    }
  }
  .banner-swiper.ant-carousel .slick-dots-bottom {
    bottom: 30px;
  }
}
</style>
