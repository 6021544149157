<template>
  <div>
    <!-- begin footer -->
    <template v-if="clientWidth > 768">
      <div class="footer clearfix">
        <div class="footer-panel clearfix">
          <div class="logo">
            <img :src="foot.logo" style="width: 2.5rem" />
          </div>
          <div class="f_link" v-for="(item, index) in showMenus" :key="index">
            <p class="ft-title">{{ item.name }}</p>
            <ul>
              <li v-for="(menuItem, index) in item.children || []" :key="index">
                <a
                  href="https://pekhongyuan.zhiye.com/"
                  v-if="menuItem.name === '职位投递'"
                  target="_blank"
                  >{{ menuItem.name }}</a
                >
                <a href="" @click.prevent="jump(menuItem)" v-else>{{
                  menuItem.name
                }}</a>
              </li>
            </ul>
          </div>
          <div class="code2">
            <img :src="foot.qrcode" />
            <img src="@/assets/code.jpg" />
          </div>
        </div>
      </div>
      <div class="footer-bttm">
        <div class="content">
          <span class="left"
            ><a
              href="https://www.beijing2022.cn/"
              title="北京冬奥组委会官网"
              target="_blank"
              style="color: #717171"
            >
              Copyright2014-{{
                new Date().getFullYear()
              }}五洲通大药房版权所有&nbsp;&nbsp;&nbsp;&nbsp;</a
            ></span
          >
          <span class="right">京ICP备15036015号-1</span>
        </div>
      </div>
    </template>
    <template v-if="clientWidth <= 768">
      <div class="footer footerflex clearfix">
        <div class="logo">
          <img :src="foot.logo" style="width: 50px; height: 50px" />
          <div class="content">
            <span class="left"
              ><a
                href="https://www.beijing2022.cn/"
                title="北京冬奥组委会官网"
                target="_blank"
                style="color: #717171"
              >
                Copyright2014-{{
                  new Date().getFullYear()
                }}五洲通大药房版权所有版权所有&nbsp;&nbsp;&nbsp;&nbsp;</a
              ></span
            >
          </div>
        </div>
        <div class="code2">
          <img src="http://www.pekhongyuan.com/pic/hongyuan/images/code2.png" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { MENUS } from './menus/menu.json'
import { isUndefined } from 'lodash-es'
import { prevItem } from './menus/state'
import { getFoot } from '@/api/system'
export default {
  props: ['footer'],
  data () {
    return {
      MENUS,
      prevItem,
      clientWidth: document.documentElement.clientWidth,
      foot: {}
    }
  },
  computed: {
    showMenus () {
      return MENUS.filter(item => {
        if (isUndefined(item.noShowFooter)) return true
        return false
      })
    }
  },
  methods: {
    jump (item) {
      console.log(item)
      if (JSON.stringify(item) === JSON.stringify(this.prevItem)) {
        return
      }
      const { router, params } = item
      console.log(router)
      if (router) {
        if (params) {
          this.$router.push({ name: router, params: { ...params } })
        } else {
          this.$router.push({ name: router })
        }
      }
      this.prevItem = item
    }
  },
  async mounted () {
    const allData = await getFoot()
    if (allData.code === 200) {
      this.foot = allData.data
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  // min-width: 1200px;
  color: #fff;
  background-color: #222;
  &-panel {
    max-width: 1550px;
    // min-width: 1200px;
    padding: 60px 0 50px;
    margin: auto;
    line-height: 40px;
    box-sizing: border-box;
    display: flex;
    .logo {
      width: 20%;
      img {
        width: 100%;
      }
    }
    > .f_link {
      width: 12%;
      padding-left: 40px;
    }
    .ft-title {
      text-align: left;
      padding-bottom: 10px;
      margin-bottom: 10px;
      position: relative;
      line-height: 1.2;
      font-size: 18px;
      &:after {
        content: "";
        width: 25px;
        height: 2px;
        background-color: #336fd0;
        position: absolute;
        bottom: 0;
        left: 5px;
        display: block;
      }
    }
    .f_link {
      li {
        font-size: 14px;
        color: #a3a3a3;
        line-height: 2.5;
      }
      a {
        color: #a3a3a3;
        text-decoration: none;
        &:hover {
          color: darken(#a3a3a3, 10%);
        }
      }
    }
  }
}
.footer-bttm {
  width: 100%;
  // min-width: 1200px;
  border-top: 1px solid #343434;
  background-color: #222;
  .content {
    max-width: 1540px;
    // min-width: 1200px;
    margin: auto;
    padding: 0 50px;
    color: #717171;
    line-height: 62px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
}
.footerflex {
  padding: 10px 15px;
  display: flex;
}
.code2 {
  display: flex;
  img {
    width: 200px;
    height: 200px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 900px) {
  .f_link {
    display: none;
  }
  .logo {
    margin-right: 15px;
    img {
      height: 20px;
    }
  }
  .code2 {
    display: none;
  }
}
</style>
