export const MENUS = [{
  noShowFooter: false,
  name: '首页',
  router: 'main'
}, {
  name: '走进五洲通',
  children: [{
    name: '公司介绍',
    router: 'About',
    params: { type: '1' }
  }, {
    name: '发展历程',
    router: 'About',
    params: { type: '2' }
  }, {
    name: '企业文化',
    router: 'About',
    params: { type: '3' }
  }]
}, {
  name: '合作加盟',
  children: [{
    name: '合作加盟',
    router: 'Business',
    params: { type: '7' }
  }]
}, {
  name: '新闻资讯',
  children: [{
    name: '公司动态',
    router: 'News',
    params: { type: '1' }
  }, {
    name: '媒体报道',
    router: 'News',
    params: { type: '2' }
  }]
}, {
  name: '联系我们',
  children: [{
    name: '联系我们',
    router: 'Contact'
  }]
}
  // {
  //   name: '加入我们',
  //   children: [{
  //     name: '人才理念',
  //     router: 'Concept'
  //   }, {
  //     name: '职位投递'
  //   }]
  // }, {
  //   name: 'CN/EN',
  //   noShowFooter: false
  // }
]
