<template>
  <div class="w1200" ref="box">
    <div class="partner">
      <carousel-3d
        :display="1"
        :height="height"
        :width="width"
        :border="0"
        controlsVisible
        autoplay
        v-if="cooperationList.length > 0"
      >
        <slide v-for="(item, index) in cooperationList"  :key="index" :index="index">
          <img :src="item.link" alt="" />
        </slide>
      </carousel-3d>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
/* import partner13 from 'http://www.pekhongyuan.com/pic/hongyuan/images/partner13.png'
import partner12 from 'http://www.pekhongyuan.com/pic/hongyuan/images/partner12.png'
import partner11 from 'http://www.pekhongyuan.com/pic/hongyuan/images/partner11.png' */

var slides = [
  { src: 'http://www.pekhongyuan.com/pic/hongyuan/images/partner13.png' },
  { src: 'http://www.pekhongyuan.com/pic/hongyuan/images/partner12.png' },
  { src: 'http://www.pekhongyuan.com/pic/hongyuan/images/partner11.png' }
]

const getSize = box => {
  const width = box.offsetWidth
  const radio = 0.297297297297
  const height = radio * width
  return {
    width,
    height
  }
}

export default {
  props: {
    cooperationList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Carousel3d,
    Slide
  },
  mounted () {
    const container = this.$refs.box
    const resize = () => {
      const { width, height } = getSize(container)
      this.width = width
      this.height = height
    }
    resize()
    window.onresize = resize
  },
  data () {
    return {
      slides,
      width: 0,
      height: 0
    }
  }
}
</script>
<style lang="less" scoped>
.partner {
  margin-left: -40px;
  margin-bottom: -40px;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 40px;
  /deep/ .carousel-3d-container {
    overflow: inherit;
  }
  /deep/ .carousel-3d-slide {
    background-color: transparent;
  }
  /deep/ .carousel-3d-controls {
    margin-left: -60px;
    margin-right: -60px;
    width: auto;
    right: 0;
    .prev:hover {
      opacity: 1;
      span {
        background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left-cur.png')
          no-repeat;
      }
    }
    .prev span {
      font-size: 0;
      line-height: 1.25px;
      width: 40px;
      height: 64px;
      display: block;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left.png')
        no-repeat;
      background-size: 100% 100%;
    }
    .next:hover {
      opacity: 1;
      span {
        background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right-cur.png')
          no-repeat;
      }
    }
    .next span {
      font-size: 0;
      line-height: 1.25px;
      width: 40px;
      height: 64px;
      display: block;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right.png')
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
@media screen and (max-width: 900px) {
  .w1200 {
    overflow: hidden;
  }
  .partner {
    margin-left: 0px;
    margin-bottom: -40px;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 40px;
    /deep/ .carousel-3d-container {
      overflow: inherit;
    }
    /deep/ .carousel-3d-slide {
      background-color: transparent;
    }
    /deep/ .carousel-3d-controls {
      margin-left: -60px;
      margin-right: -60px;
      width: auto;
      right: 0;
      .prev:hover {
        opacity: 1;

        span {
          background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left-cur.png')
            no-repeat;
        }
      }
      .prev span {
        font-size: 0;
        line-height: 1.25px;
        width: 40px;
        height: 64px;
        display: block;
        background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left.png')
          no-repeat;
        background-size: 100% 100%;
        display: none;
      }
      .next:hover {
        opacity: 1;
        span {
          background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right-cur.png')
            no-repeat;
        }
      }
      .next span {
        font-size: 0;
        line-height: 1.25px;
        width: 40px;
        height: 64px;
        display: block;
        background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right.png')
          no-repeat;
        background-size: 100% 100%;
        display: none;
      }
    }
  }
}
</style>
