<template>
  <ol class="dropdown-menu" v-show="status">
    <li v-for="(citem, index) in data" :key="index">
      <a
        href="https://pekhongyuan.zhiye.com/"
        v-if="citem.name === '职位投递'"
        target="_blank"
      >
        {{ citem.name }}</a
      >
      <a href="#" @click="toggle($event, citem)" v-else> {{ citem.name }}</a>
    </li>
  </ol>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      status: false
    }
  },
  methods: {
    open () {
      this.status = true
    },
    close () {
      this.status = false
    },
    toggle (event, item) {
      event.preventDefault()
      this.$emit('item', item)
      this.close()
    }
  }
}
</script>
<style lang="less">
.dropdown-menu {
  background-color: rgb(78, 138, 197);
  li {
    text-align: center;
    line-height: 30px;
    &:hover {
      background-color: rgb(9, 59, 124);
    }
    a {
      color: #fff;
      padding: 0 8px;
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .dropdown-menu {
    background: #3b3b3b;
  }
}
</style>
