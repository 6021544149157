import { Get, Post } from '@/utils/fetch'
// 首页接口
export const getIndexInfo = () => {
  return Get('/', {})
}
// 搜索
export const search = ({ pageNum, pageSize, info }) => {
  return Get('/search', { pageNum, pageSize, info })
}
export const joinInfoAdd = (info) => {
  return Post('/join-info/add-join-info', info)
}
export const getList = ({ pageNum, pageSize, info }) => {
  return Get('/join-info/get-join-info-list', { pageNum, pageSize, info })
}
