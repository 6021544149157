<template>
  <div>
    <MainHeader />
    <MainBanner :banners="banners" />
    <MainContent
      :newsInfo="newsInfo"
      :newList="newList"
      :background="background"
    />
    <MainPartner :cooperationList="cooperationList" />
    <MainFooter />
  </div>
</template>
<script>
import MainHeader from './components/main-header'
import MainBanner from './components/main-banner'
import MainContent from './components/main-content'
import MainFooter from '../public/footer'
import MainPartner from './components/main-partner'

import { getIndexInfo } from '@/api/home'
export default {
  data () {
    return {
      tit1: 'http://www.pekhongyuan.com/pic/hongyuan/images/title1.png',
      tit2: 'http://www.pekhongyuan.com/pic/hongyuan/images/title2.png',
      tit3: 'http://www.pekhongyuan.com/pic/hongyuan/images/title3.png',
      tit4: 'http://www.pekhongyuan.com/pic/hongyuan/images/title4.png',
      newList: [],
      newsOutline: {},
      newsInfo: {},
      banners: [],
      cooperationList: [],
      background: '',
      clientWidth: document.documentElement.clientWidth
    }
  },
  components: {
    MainHeader,
    MainBanner,
    MainContent,
    MainFooter,
    MainPartner
  },
  async mounted () {
    const allData = await getIndexInfo()
    if (allData.code === 200) {
      this.newsInfo = allData.data.topNews
      this.newList = allData.data.news
      this.background = allData.data.globalLayout
      this.banners = allData.data.pictures
      this.cooperationList = allData.data.cooperationList
    }
  }
}
</script>
