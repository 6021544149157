<template>
  <div class="swiper-news relative">
    <div v-if="clientWidth > 900">
      <Swiper :options="swiperOptions">
        <SwiperSlide v-for="(i, index) in newList" :key="index">
          <Item :item="i" />
        </SwiperSlide>
      </Swiper>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>
    <div class="list" v-if="clientWidth <= 900">
      <div v-for="(i, index) in newList" :key="index">
        <Item :item="i" />
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import SwiperCore, { Pagination, Navigation } from 'swiper'

import 'swiper/swiper-bundle.css'
import Item from './new-swiper-item'

import 'swiper/components/pagination/pagination.less'

SwiperCore.use([Pagination, Navigation])

export default {
  props: {
    newList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      clientWidth: document.documentElement.clientWidth,
      swiperOptions: {
        slidesPerView: document.documentElement.clientWidth > 900 ? 3 : 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Item
  },
  directives: {
    swiper: directive
  }
}
</script>
<style lang="less" scoped>
.swiper-news {
  .swiper-button-prev {
    position: absolute;
    width: auto;
    height: auto;
    left: -60px;
  }
  .swiper-button-next {
    position: absolute;
    width: auto;
    height: auto;
    right: -60px;
  }
  .swiper-button-prev:hover {
    &:after {
      opacity: 1;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left-cur.png')
        no-repeat;
    }
  }
  .swiper-button-prev:after {
    font-size: 0;
    line-height: 1.25px;
    width: 40px;
    height: 64px;
    display: block;
    background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left.png')
      no-repeat;
    background-size: 100% 100%;
  }
  .swiper-button-next:hover {
    &:after {
      opacity: 1;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right-cur.png')
        no-repeat;
    }
  }
  .swiper-button-next:after {
    font-size: 0;
    line-height: 1.25px;
    width: 40px;
    height: 64px;
    display: block;
    background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right.png')
      no-repeat;
    background-size: 100% 100%;
  }
}
@media screen and(max-width:900px) {
  .list {
    padding: 15px;
  }
  .swiper-news {
    .swiper-button-next {
      right: 15px;
      width: 20px;
      height: 30px;
    }
    .swiper-button-next:after {
      font-size: 0;
      line-height: 1.25px;
      width: 20px;
      height: 30px;
      display: block;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right.png')
        no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next:hover {
      &:after {
        font-size: 0;
        line-height: 1.25px;
        width: 20px;
        height: 30px;
        display: block;
        background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-right.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .swiper-button-prev {
      position: absolute;
      width: 20px;
      height: 30px;
      left: 15px;
    }
    .swiper-button-prev:hover {
      &:after {
        font-size: 0;
        line-height: 1.25px;
        width: 20px;
        height: 30px;
        display: block;
        background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
    .swiper-button-prev:after {
      font-size: 0;
      line-height: 1.25px;
      width: 20px;
      height: 30px;
      display: block;
      background: url('http://www.pekhongyuan.com/pic/hongyuan/images/0602-left.png')
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
