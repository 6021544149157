<template>
  <div class="group-business flexAround">
    <template v-if="clientWidth > 900">
      <div class="gb-box" v-on:click.prevent="go('/business/0')">
        <img
          class="bgImg"
          src="https://www.lnwztdyf.com/image/6.jpg"
          title="创业分析"
        />
        <div class="bg-box-current" />
        <img :class="`txtImg item1`" :src="a1" />
        <div class="detail">
          <div>市场投资定位</div>
          <div>区域政策解读</div>
          <div>项目营收分析</div>
        </div>
      </div>
      <div class="gb-box" v-on:click.prevent="go('/trade')">
        <img
          class="bgImg"
          src="https://www.lnwztdyf.com/image/11.jpg"
          title="筹建服务"
        />
        <div class="bg-box-current" />
        <img :class="`txtImg item2`" :src="a2" />
        <div class="detail">
          <div>分析地域环境</div>
          <div>客户群体画像</div>
          <div>店面装修方案</div>
        </div>
      </div>
      <div class="gb-box" v-on:click.prevent="go('/supply-chain')">
        <img
          class="bgImg"
          src="https://www.lnwztdyf.com/image/7.jpg"
          title="开业服务"
        />
        <div class="bg-box-current" />
        <img :class="`txtImg item3`" :src="a3" />
        <div class="detail">
          <div>协助资质办理</div>
          <div>开业活动策划</div>
          <div>店长驻店指导</div>
        </div>
      </div>
      <div class="gb-box" v-on:click.prevent="go('/house-build')">
        <img
          class="bgImg"
          src="https://www.lnwztdyf.com/image/8.jpg"
          title="培训服务"
        />
        <div class="bg-box-current" />
        <img :class="`txtImg item4`" :src="a4" />
        <div class="detail">
          <div>人员管理培训</div>
          <div>软件系统培训</div>
          <div>店面验收培训</div>
        </div>
      </div>
      <div class="gb-box" v-on:click.prevent="go('/house-build')">
        <img
          class="bgImg"
          src="https://www.lnwztdyf.com/image/9.jpg"
          title="营销服务"
        />
        <div class="bg-box-current" />
        <img :class="`txtImg item4`" :src="a5" />
        <div class="detail">
          <div>营销拓客支持</div>
          <div>运营巡店支持</div>
          <div>疑难日常解惑</div>
        </div>
      </div>
      <div class="gb-box" v-on:click.prevent="go('/house-build')">
        <img
          class="bgImg"
          src="https://www.lnwztdyf.com/image/10.jpg"
          title="政策研究"
        />
        <div class="bg-box-current" />
        <img :class="`txtImg item4`" :src="a6" />
        <div class="detail">
          <div>行业政策解读</div>
          <div>应策调整门店</div>
          <div>高效保驾护航</div>
        </div>
      </div>
    </template>
    <template v-if="clientWidth <= 900">
      <img
        v-on:click.prevent="go('/business/0')"
        class="bgImg"
        src="https://www.lnwztdyf.com/image/6.jpg"
        title="创业分析"
      />
      <img
        v-on:click.prevent="go('/trade')"
        class="bgImg"
        src="https://www.lnwztdyf.com/image/11.jpg"
        title="筹建服务"
      />
      <img
        v-on:click.prevent="go('/supply-chain')"
        class="bgImg"
        src="https://www.lnwztdyf.com/image/7.jpg"
        title="开业服务"
      />
      <img
        v-on:click.prevent="go('/house-build')"
        class="bgImg"
        src="https://www.lnwztdyf.com/image/8.jpg"
        title="培训服务"
      />
      <img
        v-on:click.prevent="go('/house-build')"
        class="bgImg"
        src="https://www.lnwztdyf.com/image/9.jpg"
        title="营销服务"
      />
      <img
        v-on:click.prevent="go('/house-build')"
        class="bgImg"
        src="https://www.lnwztdyf.com/image/10.jpg"
        title="政策研究"
      />
    </template>
  </div>
</template>
<script>
import { data as Items } from './business.json'
import a1 from '@/assets/a1.png'
import a2 from '@/assets/a2.png'
import a3 from '@/assets/a3.png'
import a4 from '@/assets/a4.png'
import a5 from '@/assets/a5.png'
import a6 from '@/assets/a6.png'
export default {
  data () {
    return {
      Items,
      clientWidth: document.documentElement.clientWidth,
      a1,
      a2,
      a3,
      a4,
      a5,
      a6
    }
  },
  methods: {
    go (href) {
      // this.$router.push({ path: href })
    }
  }
}
</script>
<style lang="less" scoped>
.group-business {
  padding: 20px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // min-width: 1200px;
  margin: 0 auto;
}
</style>
<style lang="less" scoped>
.gb-box {
  position: relative;
  width: 25%;
  box-sizing: border-box;
  margin: 5px;
  .bg-box-current {
    position: absolute;
    background: #529fcc;
    transition: all ease-in-out 300ms;
    width: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  &:hover {
    cursor: pointer;
    .bg-box-current {
      width: 100%;
    }
    .detail {
      display: flex;
    }
  }
  .bgImg {
    width: 100%;
  }
  .txtImg {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    width: 30px;
  }
  .detail {
    position: absolute;
    display: none;
    left: 70px;
    top: 72px;
    z-index: 3;
    color: #fff;
    font-size: 18px;
    div {
      width: 18px;
      margin-left: 20px;
    }
  }
  .item1 {
    // width: 33px;
  }
  .item3 {
    // width: 33px;
  }
}
@media screen and (max-width: 900px) {
  .bgImg {
    width: 68px;
  }
}
</style>
