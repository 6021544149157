<template>
  <div>
    <li class="current" @click="handleClick">
      <div class="bix">
        <a href="#" class="top-title" @click="handle">{{ item.name }}</a>
        <img
          v-if="item.children"
          :style="'transform: rotate(' + deg + 'deg);'"
          src="@/assets/top.png"
          alt=""
        />
      </div>
      <template v-if="item.children">
        <ol class="dropdown-menu" v-show="deg === 0">
          <li v-for="(citem, index) in item.children" :key="index">
            <a
              href="https://pekhongyuan.zhiye.com/"
              v-if="citem.name === '职位投递'"
              target="_blank"
            >
              {{ citem.name }}</a
            >
            <a href="#" @click="getItem(citem)" v-else> {{ citem.name }}</a>
          </li>
        </ol>
      </template>
    </li>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      deg: 180
    }
  },
  methods: {
    handle () {
      if (this.item.children) {
        if (this.item.router === 'Business') {
          this.$router.push({ name: 'Business', params: this.item.params })
        }
        return
      }
      if (this.item.router) {
        if (this.item.params) {
          this.$router.push({
            name: this.item.router,
            params: this.item.params
          })
        } else {
          this.$router.push({ name: this.item.router })
        }
      }
    },
    handleClick () {
      if (this.deg === 180) {
        this.deg = 0
      } else {
        this.deg = 180
      }
    },
    getItem (item) {
      console.log(item)
      if (JSON.stringify(item) === JSON.stringify(this.prevItem)) {
        return
      }
      const { router, params } = item
      if (router) {
        if (params) {
          this.$router.push({ name: router, params: { ...params } })
        } else {
          this.$router.push({ name: router })
        }
      }
      this.prevItem = item
    }
  }
}
</script>
<style lang="less" scoped>
.bix {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 14px;
    height: 14px;
    display: block;
    margin-right: 15px;
  }
}
</style>
