<template>
  <div class="header">
    <div class="header-panel">
        <div class="logo">
          <a href="#">
            <img alt="宏远控股" style="width: 180px;" :src="Logo"/>
          </a>
        </div>
        <div class="navAll">
          <Menus />
        </div>
    </div>
  </div>
</template>
<script>
import Menus from '../../public/header/menu'
import Logo from '@/assets/logo.png'
export default {
  components: {
    Menus
  },
  data () {
    return {
      Logo
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
  min-width: 12rem;
}
.header-panel {
  max-width: 15.2rem;
  min-width: 12rem;
  margin: auto;
  padding: 0.2rem 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.header .logo {
  width: 2.13rem;
  height: 0.47rem;
  float: left;
}

.navAll {
  font-size: 0.14rem;
  color: #fff;
  line-height: 0.4rem;
  float: right;
}
.navAll > ul > li {
  height: 0.4rem;
  padding: 0 12px;
  float: left;
  line-height: 0.4rem;
  display: inline-block;
  vertical-align: middle;
}
.navAll ul > .current > a:hover {
  border-bottom: 2px solid #6cbbe9;
}
.navAll ul li img {
  display: block;
  line-height: 0.4rem;
  vertical-align: middle;
  position: relative;
  bottom: -34%;
}
.navAll a {
  color: #fff;
  padding: 0 0.08rem;
  display: block;
}
@media screen and (max-width: 900px) {
  .logo img {
    width: 80%;
    margin-top: 10px;
  }
  .header .logo {
    width: 5.13rem;
  }
}
</style>
