<template>
  <div class="swiper-slide">
    <div v-if="clientWidth > 900">
      <dl>
        <dt>
          <a target="_blank" @click="goNew(item)"
            ><img :src="item.coverMap"
          /></a>
        </dt>
        <dd>
          <p class="tit">{{ item.title }}</p>
          <p class="txt">{{ item.newAbstract }}</p>
        </dd>
      </dl>
      <p class="bttm">
        {{ item.createTime
        }}<a
          target="_blank"
          @click="goNew(item)"
          class="none900"
          style="float:right;padding-right: 0.7rem;"
          >>更多</a
        >
      </p>
    </div>
    <div v-if="clientWidth <= 900">
      <a target="_blank" @click="goNew(item)">
        <p class="tit">{{ item.title }}</p>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      clientWidth: document.documentElement.clientWidth
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.tit {
  font-size: 15px;
  color: #333;
  line-height: 2;
  font-weight: bold;
}
.swiper-slide {
  dl {
    width: 100%;
    // 给页面加了个
    height: 4.5rem;
    padding: 10px 60px;
    padding-left: 0px;
    img {
      width: 100%;
    }
  }
  dd {
    padding: 10px 0 30px;
    position: relative;

    .txt {
      font-size: 12px;
      color: #808080;
      line-height: 1.6;
    }
    .bttm {
      line-height: 30px;
      font-size: 12px;
      color: #666;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 100;
    }
    a {
      font-size: 11px;
      color: #336fd0;
      float: right;
    }
  }
}
@media screen and(max-width:900px) {
  .swiper-slide dl {
    height: auto;
    padding: 10px 15px;
  }
  .bttm {
    padding: 10px 15px;
    display: none;
  }
  .none900 {
    display: none;
  }
  .tit {
    display: flex;
    align-items: center;
  }
  .tit::before {
    content: '';
    display: block;
    min-width: 8px;
    min-height: 8px;
    background: #000;
    margin-right: 10px;
    border-radius: 50%;
  }
}
</style>
