<template>
  <div>
    <template v-if="clientWidth <= 768">
      <div class="tip-menu" @click="show">
        <img src="@/assets/menu.png" alt="" />
      </div>
      <drawer
        title="五洲通大药房"
        :visible.sync="dialogVisible"
        width="200px"
        close-on-click-modal
        main-background="#000"
        title-color="#fff"
        header-background="#000"
      >
        <ul class="menu">
          <Mobile v-for="(item, index) in MENUS" :key="index" :item="item" />
          <!-- <div class="toggle-menu-search" slot="content">
            <a-input-search
              @blur="closeBox"
              placeholder="请输入搜索内容"
              @search="search"
              v-model="find"
            />
          </div> -->
        </ul>
      </drawer>
    </template>
    <ul class="menu" v-if="clientWidth > 768">
      <DropDownMenu v-for="(item, index) in MENUS" :key="index" :item="item" />
    </ul>
    <!-- <a-alert :show-icon="true" v-if="notEntered" message="请输入关键字"  type="warning"  closable   />
    <a-alert :show-icon="true" v-if="notFound" message="未找到相关信息"  type="info"  closable    />
    <a-alert :show-icon="true" v-if="callFailed" message="接口调用失败"  type="error"  closable   /> -->
  </div>
</template>
<script>
import { MENUS } from '../menus/menu.json'
import DropDownMenu from '../menus/dropdownmenu'
import Drawer from './drawer.vue'
import Mobile from './mobileHeader.vue'
import { search } from '../../../api/home'

export default {
  data () {
    return {
      MENUS,
      toggle: false,
      find: '',
      notEntered: false,
      notFound: false,
      callFailed: false,
      dialogVisible: false,
      clientWidth: document.documentElement.clientWidth,
      pageSize: 7,
      pageNum: 1
    }
  },
  methods: {
    toggleBox () {
      this.toggle = !this.toggle
    },
    closeBox () {
      this.toggle = false
    },
    async search () {
      if (this.find === '') {
        this.notEntered = true
        this.$message.error('请输入关键字')
        return
      }
      const searchData = await search({ pageNum: this.pageNum, pageSize: this.pageSize, info: this.find })
      if (searchData.code === 200) {
        if (searchData.data.content.length === 0) {
          this.$message.error('未查询到相关信息')
        } else if (searchData.data.content.length === 1) {
          this.goNew(searchData.data.content[0])
        } else {
          this.$router.push({
            name: 'Search',
            params: { find: this.find }
          })
        }
        this.find = ''
      } else {
        this.$message.error('搜索异常，请联系管理员')
      }
    },
    show () {
      this.dialogVisible = true
    },
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  },
  components: {
    DropDownMenu,
    Drawer,
    Mobile
  }
}
</script>
<style lang="less" scoped>
.tip-menu {
  width: 35px;
  height: 30px;
  background: #000;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 6px;

  img {
    width: 30px;
    display: block;
    margin: 0 auto;
  }
}

.menu {
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  float: right;
}

.menu > li {
  // 加了后 电脑端看不到
  // height: 40px;
  padding: 0 12px;
  float: left;
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
}

.menu > .current > a:hover {
  border-bottom: 2px solid #6cbbe9;
}

.menu li img {
  display: block;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  bottom: -34%;
}

.menu a {
  color: #fff;
  padding: 0 8px;
  display: block;
}

.toggle-menu {
  position: relative;

  &-icon {
    background: url("http://www.pekhongyuan.com/pic/hongyuan/images/icon-search.png")
      no-repeat;
    background-position: center center;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 10px;
  }

  &-search {
    // position: absolute;
    left: 100%;
    top: 0px;
    width: 200px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}

@media screen and (max-width: 900px) {
  .menu {
    .current {
      width: 100%;
      height: auto;
      min-height: 40px;
    }
  }
}
</style>
