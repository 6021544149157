<template>
  <li class="current" @mouseenter="handleEnter" @mouseleave="handleLeave">
    <a href="#" class="top-title" @click="handle">{{ item.name }}</a>
    <template v-if="item.children">
      <DropDown :data="item.children" ref="dropdown" @item="getItem" />
    </template>
  </li>
</template>
<script>
import DropDown from './dropdown'
import { prevItem } from './state'
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    DropDown
  },
  data () {
    return {
      prevItem
    }
  },
  methods: {
    handleEnter () {
      this.$refs.dropdown && this.$refs.dropdown.open()
    },
    handleLeave () {
      this.$refs.dropdown && this.$refs.dropdown.close()
    },

    handle () {
      if (this.item.children) {
        if (this.item.router === 'Business') {
          this.$router.push({ name: 'Business', params: this.item.params })
        }
        return
      }
      if (this.item.router) {
        if (this.item.params) {
          this.$router.push({
            name: this.item.router,
            params: this.item.params
          })
        } else {
          this.$router.push({ name: this.item.router })
        }
      }
    },
    getItem (item) {
      if (JSON.stringify(item) === JSON.stringify(this.prevItem)) {
        return
      }
      const { router, params } = item
      if (router) {
        if (params) {
          this.$router.push({ name: router, params: { ...params } })
        } else {
          this.$router.push({ name: router })
        }
      }
      this.prevItem = item
    }
  }
}
</script>
<style lang="less">
.top-title {
  color: #fff;
  display: block;
  text-align: center;
  min-width: 86px;
  &:hover {
    color: #fff;
    border-bottom: 2px solid #6cbbe9;
  }
}
@media screen and(max-width:900px) {
  .top-title {
    color: #fff;
    display: block;
    text-align: center;
    min-width: 86px;
    &:hover {
      color: #fff;
      border-bottom: none;
    }
  }
}
</style>
