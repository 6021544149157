<template>
  <div class="main-title">
    <img :src="src" :alt="alt" :title="alt" />
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="less" scoped>
.main-title {
  padding: 40px 0;
  text-align: center;
  img {
    // max-width: 618px;
    // max-height: 46px;
    width: 100%;
  }
}
@media screen and(max-width:900px) {
  .main-title {
    overflow: hidden;
  }
  .main-title img {
    transform: scale(2.4);
  }
}
</style>
