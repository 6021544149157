<template>
  <div class="banner">
    <!-- <div class="w1200 relative">
      <DatePicker />
    </div> -->
    <Swiper :banner="banners" />
  </div>
</template>
<script>
// import DatePicker from './date-box'
import Swiper from './swiper.vue'
export default {
  props: ['banners'],
  components: {
    // DatePicker,
    Swiper
  }
}
</script>
<style lang="less" scoped>
// @media screen and (max-width: 1199px) {
//     .banner {
//       width: 1200px;
//     }
// }
// @media screen and (min-width: 1200px) {
//   .banner {
//     width: 100%
//   }
// }
.banner {
  min-width: 12rem;
  position: relative;
  height: auto;
  overflow: hidden;
}
</style>
