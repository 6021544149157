export const data = [
  {
    href: '/business/0',
    bgImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/img11.jpg',
    iis: 'http://www.pekhongyuan.com/pic/hongyuan/images/i5-0.png',
    text: 'http://www.pekhongyuan.com/pic/hongyuan/images/img11.png',
    lines: ['高效可靠的服务', '全面完整的业务链条', '可复制的商业模式'],
    class: 'item1'
  },
  {
    href: '/trade',
    bgImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/img12.jpg',
    text: 'http://www.pekhongyuan.com/pic/hongyuan/images/img12.png',
    iis: 'http://www.pekhongyuan.com/pic/hongyuan/images/i5-1.png',
    lines: [
      '高质量的物流清报关 保税仓等服务',
      '向国内提供丰富品质上乘的商品 ',
      '带来更高品质 更多元化的消费选择'
    ],
    class: 'item2'
  },
  {
    href: '/supply-chain',
    bgImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/img13.jpg',
    text: 'http://www.pekhongyuan.com/pic/hongyuan/images/img13.png',
    iis: 'http://www.pekhongyuan.com/pic/hongyuan/images/i5-2.png',

    lines: ['聚焦新目标 新任务提质增效', '致力于城市运营和城市建设相关业务'],
    class: 'item3'
  },
  {
    href: '/house-build',
    bgImg: 'http://www.pekhongyuan.com/pic/hongyuan/images/img14.jpg',
    text: 'http://www.pekhongyuan.com/pic/hongyuan/images/img14.png',
    iis: 'http://www.pekhongyuan.com/pic/hongyuan/images/i5-3.png',
    lines: [
      '依托临空经济',
      '先后开发多个地标性项目',
      '累计开发面积近百万平方米',
      '取得国家房地产开发企业四级资质'
    ],
    class: 'item4'
  }
]
